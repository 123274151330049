<template>
  <v-form class="multi-col-validation">
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <strong>{{ $t('lbl.method') }}:</strong> <code>POST</code>
            <br>
            <br>
            <code>
              {{ url }}
            </code>
            <br>
            <strong>JSON - {{ $t('lbl.request') }}</strong>
            <json-viewer
              :value="{'api_key':api_key,
                       'secret_token':secret_token,
                       'page':parseInt(page),
                       'per_page':parseInt(per_page),
                       'cant_adults':cotizadorFlight.cant_adults,
                       'cant_ninnos':cotizadorFlight.cant_ninnos,
                       'cant_infant':cotizadorFlight.cant_infant,
                       'aerolinea':cotizadorFlight.aerolinea,
                       'onlyIda':cotizadorFlight.onlyIda,
                       'destinosMultiples':cotizadorFlight.destinosMultiples,
                       'destinos':destinosCotizadorFlight,
                       'filter': filter}"
              :sort="true"
              :boxed="true"
            ></json-viewer>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="jsonData != null"
        cols="12"
        md="6"
      >
        <strong>JSON - {{ $t('lbl.response') }}</strong>
        <json-viewer
          :value="jsonData"
          :sort="true"
          :boxed="true"
        ></json-viewer>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <strong>{{ $t('lbl.params') }}</strong>
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-radio-group
              v-model="radioGroup"
              row
            >
              <v-radio
                :label="$t('lbl.ida_vuelta')"
                @click="idaBack"
              ></v-radio>
              <v-radio
                :label="$t('lbl.ida')"
                @click="onlyIda"
              ></v-radio>
              <v-radio
                :label="$t('lbl.nultiple_destino')"
                @click="multiplesDestinos"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <FlightsDestinos
              v-for="(destino, index) in destinosCotizadorFlight"
              :key="Math.random() * (index + 1)"
              :index="index"
            />
            <v-row v-if="cotizadorFlight.destinosMultiples">
              <v-col
                cols="12"
                md="4"
              >
                <v-btn
                  color="primary"
                  width="100%"
                  @click="addDestinoCotizadorFligth"
                >
                  {{ $t('lbl.addFligth') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="2"
                class="text-center"
              >
                <v-text-field
                  v-model="cotizadorFlight.cant_adults"
                  :label="$t('lbl.adultos')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  min="0"
                  :prepend-inner-icon="icons.mdiMinus"
                  :append-icon="icons.mdiPlus"
                  @click:prepend-inner="decrementAdultosCotizadorFligth"
                  @click:append="incrementAdultosCotizadorFligth"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-center"
              >
                <v-text-field
                  v-model="cotizadorFlight.cant_ninnos"
                  :label="$t('lbl.childs')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  min="0"
                  :prepend-inner-icon="icons.mdiMinus"
                  :append-icon="icons.mdiPlus"
                  @click:prepend-inner="decrementChildCotizadorFligth"
                  @click:append="incrementChildCotizadorFligth"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-center"
              >
                <v-text-field
                  v-model="cotizadorFlight.cant_infant"
                  :label="$t('lbl.infants')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  min="0"
                  :prepend-inner-icon="icons.mdiMinus"
                  :append-icon="icons.mdiPlus"
                  @click:prepend-inner="decrementInfantCotizadorFligth"
                  @click:append="incrementInfantCotizadorFligth"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="cotizadorFlight.aerolinea"
                  :items="itemsAerolinea"
                  :search-input.sync="searchAerolinea"
                  hide-details
                  hide-selected
                  :label="$t('permiso.aerolinea')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('permiso.aerolinea') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="page"
              outlined
              :label="$t('lbl.page')"
              hide-details
              class="mb-3"
              dense
              type="number"
              min="1"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="per_page"
              outlined
              :label="$t('lbl.perPage')"
              hide-details
              class="mb-3"
              dense
              type="number"
              min="1"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <strong>{{ $t('lbl.filtros') }}</strong>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="filter.aerolinea"
              :items="itemsFilterAerolinea"
              :search-input.sync="searchFilterAerolinea"
              hide-details
              hide-selected
              :label="$t('permiso.aerolinea')"
              outlined
              dense
              item-text="name"
              item-value="id"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('permiso.aerolinea') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <span v-text="item.name"></span>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="filter.escala"
              :items="tiposEscalas"
              :label="$t('lbl.escalas')"
              outlined
              dense
              hide-details
              item-text="name"
              item-value="slug"
              cols="12"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <h4
              class="pl-0 mb-0 pr-6"
              subtitle-2
            >
              {{ $t('lbl.durationEscala') }}:
              <strong color="primary">{{
                $t('lbl.durationEscalaRango', { time: filter.duracionEscala })
              }}</strong>
            </h4>
            <v-slider
              v-model="filter.duracionEscala"
              max="60"
              min="0"
              hide-details
            ></v-slider>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <h4
              class="pl-0 mb-0 pr-6"
              subtitle-2
            >
              {{ $t('lbl.durationViaje') }}:
              <strong>{{ $t('lbl.durationEscalaRango', { time: filter.duracionViaje }) }}</strong>
            </h4>
            <v-slider
              v-model="filter.duracionViaje"
              max="60"
              min="0"
              hide-details
            ></v-slider>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-autocomplete
              v-model="filter.airport"
              :items="itemsFilterAirport"
              :search-input.sync="searchFilterAirport"
              :label="$t('lbl.airport')"
              outlined
              dense
              item-text="name"
              item-value="id"
              clearable
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('lbl.airport') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-icon left>
                  {{ icons.mdiAirplaneTakeoff }}
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ item }">
                {{ item.name }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="filter.equipaje"
              :items="tiposEquipaje"
              :label="$t('lbl.bag')"
              outlined
              dense
              hide-details
              item-text="name"
              item-value="slug"
              cols="12"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <h4
              class="pl-0 pr-6 mb-2"
              subtitle-2
            >
              {{ $t('lbl.frecuencias') }}:
            </h4>
            <v-row>
              <v-col
                cols="1"
                class="pb-0 mr-5"
              >
                <v-checkbox
                  v-model="filter.frecuencias"
                  value="0"
                  class="mt-0"
                  label="Domingo"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col
                cols="1"
                class="pb-0"
              >
                <v-checkbox
                  v-model="filter.frecuencias"
                  value="1"
                  class="mt-0"
                  label="Lunes"
                  hide-details
                >
                </v-checkbox>
              </v-col>
              <v-col
                cols="1"
                class="pb-0 mr-1"
              >
                <v-checkbox
                  v-model="filter.frecuencias"
                  value="2"
                  class="mt-0"
                  label="Martes"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col
                cols="1"
                class="pb-0 mr-7"
              >
                <v-checkbox
                  v-model="filter.frecuencias"
                  value="3"
                  class="mt-0"
                  label="Miércoles"
                  hide-details
                >
                </v-checkbox>
              </v-col>
              <v-col
                cols="1"
                class="pb-0 mr-2"
              >
                <v-checkbox
                  v-model="filter.frecuencias"
                  value="4"
                  class="mt-0"
                  label="Jueves"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col
                cols="1"
                class="pb-0 mr-2"
              >
                <v-checkbox
                  v-model="filter.frecuencias"
                  value="5"
                  class="mt-0"
                  label="Viernes"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col
                cols="3"
                class="pb-0"
              >
                <v-checkbox
                  v-model="filter.frecuencias"
                  value="6"
                  class="mt-0"
                  label="Sábado"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="2"
            class="mt-5"
          >
            <v-btn
              color="primary"
              block
              dark
              large
              :loading="loading"
              @click="save()"
            >
              <span>{{ $t('btn.send') }}</span>
              <v-icon>{{ icons.mdiUpdate }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify, mdiDeleteOutline, mdiSquareEditOutline, mdiPlus, mdiMinus, mdiArrowLeft, mdiUpdate, mdiAirplaneTakeoff,
} from '@mdi/js'
import FlightsDestinos from '../utils/FlightsDestinos.vue'

// eslint-disable-next-line import/no-unresolved
// import LeafletExample from '@/views/leaflet/Example.vue'

export default {
  components: {
    FlightsDestinos,
  },
  data() {
    return {
      isLoading: true,
      jsonFile: null,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiMinus,
        mdiArrowLeft,
        mdiUpdate,
        mdiAirplaneTakeoff,
      },
      loading: false,
      jsonData: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      url: `${process.env.VUE_APP_API_URL}flights/availability`,
      api_key: sessionStorage.getItem('api_key'),
      secret_token: sessionStorage.getItem('secret_token'),
      page: 1,
      per_page: 10,
      date: this.$moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
      radioGroup: 0,
      aerolineas: [],
      itemsAerolinea: [],
      searchAerolinea: null,
      filter: {
        aerolinea: null,
        escala: null,
        frecuencias: [],
        airport: null,
        equipaje: null,
        duracionEscala: 20,
        duracionViaje: 20,
      },
      searchFilterAerolinea: null,
      itemsFilterAerolinea: [],
      tiposEscalas: [],
      itemsFilterAirport: [],
      searchFilterAirport: null,
      tiposEquipaje: [],
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
    }),
  },
  watch: {
    searchAerolinea(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterAerolinea(val.toLowerCase())
      } else {
        this.itemsAerolinea = []
      }
    },
    searchFilterAerolinea(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterFilterAerolinea(val.toLowerCase())
      } else {
        this.itemsFilterAerolinea = []
      }
    },
    searchFilterAirport(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterAirport(val.toLowerCase())
      }
    },
  },
  created() {
    this.getAerolineas()
    this.tiposEscalas.push({ name: this.$t('lbl.escala1'), slug: 'all' })
    this.tiposEscalas.push({ name: this.$t('lbl.escala2'), slug: 'direct' })
    this.tiposEscalas.push({ name: this.$t('lbl.escala3'), slug: 'up_to_scale' })

    this.tiposEquipaje.push({ name: 'Indiferente', slug: 'all' })
    this.tiposEquipaje.push({ name: 'Sin equipaje', slug: 'no_baggage' })
    this.tiposEquipaje.push({ name: 'Con equipaje', slug: 'baggage' })
  },
  methods: {
    ...mapMutations([
      'addDestinoCotizadorFligth',
      'resetDestinoCotizadorFligth',
      'incrementAdultosCotizadorFligth',
      'decrementAdultosCotizadorFligth',
      'incrementChildCotizadorFligth',
      'decrementChildCotizadorFligth',
      'incrementInfantCotizadorFligth',
      'decrementInfantCotizadorFligth',
    ]),
    filterAerolinea(v) {
      this.itemsAerolinea = []
      if (v === '') {
        this.itemsAerolinea = []
      } else {
        this.itemsAerolinea = this.aerolineas.filter(e => e.name.toLowerCase())
      }
    },
    filterFilterAerolinea(v) {
      this.itemsFilterAerolinea = []
      if (v === '') {
        this.itemsFilterAerolinea = []
      } else {
        this.itemsFilterAerolinea = this.aerolineas.filter(e => e.name.toLowerCase())
      }
    },
    getAerolineas() {
      const json = {
        api_key: this.api_key,
        secret_token: this.secret_token,
        page: 1,
        per_page: 1000,
      }
      this.axios
        .post('nomenclators/aerolines', json)
        .then(res => {
          this.aerolineas = res.data.data.items
        })
    },
    filterAirport(v) {
      this.itemsFilterAirport = []
      // eslint-disable-next-line no-empty
      if (v !== '') {
        this.axios
          .post(
            'airports-names-filter-origen-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsFilterAirport.push({
                id: element.id,
                name: element.nameComodin,
                iataComodin: element.iataComodin,
                nombre: element.name,
                iata: element.iata,
              })
            })
          })
      }
    },
    setJson(e) {
      if (e) {
        this.readFile(e)
      } else {
        this.jsonData = null
      }
    },
    readFile(file) {
      const reader = new FileReader()
      reader.onload = e => {
        // eslint-disable-next-line no-unused-vars
        this.jsonData = JSON.parse(e.target.result)
      }
      reader.readAsText(file)
    },
    save() {
      if (this.destinosCotizadorFlight.length > 0) {
        this.jsonData = null
        const json = this.cotizadorFlight
        json.destinos = this.destinosCotizadorFlight
        json.api_key = this.api_key
        json.secret_token = this.secret_token
        json.page = this.page
        json.per_page = this.per_page
        json.filter = this.filter

        let proceder = true
        if (!this.cotizadorFlight.onlyIda && !this.cotizadorFlight.destinosMultiples) {
          this.destinosCotizadorFlight.forEach(element => {
            if (element.from === null || element.to === null
              || element.origen === null || element.destino === null) {
              proceder = false
            }
          })
        } else {
          this.destinosCotizadorFlight.forEach(element => {
            if (element.from === null
              || element.origen === null || element.destino === null) {
              proceder = false
            }
          })
        }

        if (proceder) {
          this.loading = true

          this.axios
            .post('flights/availability', json)
            .then(response => {
              if (!response.data.success) {
                if (response.data.status === 406) {
                  this.$toast.error(this.$t('msg.error.406'))
                }
              }
              this.jsonData = response.data
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => this.reset())
        } else {
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    reset() {
      this.loading = false
    },
    onlyIda() {
      this.cotizadorFlight.onlyIda = true
      this.cotizadorFlight.destinosMultiples = false
      this.resetDestinoCotizadorFligth()
    },
    idaBack() {
      this.cotizadorFlight.onlyIda = false
      this.cotizadorFlight.destinosMultiples = false
      this.resetDestinoCotizadorFligth()
    },
    multiplesDestinos() {
      this.cotizadorFlight.onlyIda = false
      this.cotizadorFlight.destinosMultiples = true
      this.addDestinoCotizadorFligth()
    },
  },
}
</script>
